import { ListSetting, getListSettings } from 'elements/list-settings';
import { UserModels, Role } from 'models/UserModels';

export interface ApplicationState {
  authenticated: boolean;
  sidebarOpen: boolean;
  user: UserModels.User;
  roles: Role[];
  listSettings: ListSetting;
}

export const initialState: ApplicationState = {
  authenticated: false,
  sidebarOpen: true,
  user: {} as UserModels.User,
  roles: [],
  listSettings: getListSettings(),
};
