import * as Sentry from '@sentry/browser';
import environment from './config/environment.json';

import { BaseApiService } from './services/base-api-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Aurelia, LogManager, PLATFORM } from 'aurelia-framework';

import { AppRouter } from 'aurelia-router';
import LocizeBackend from 'i18next-locize-backend';
import 'isomorphic-fetch';
import { SentryAppender } from './sentry-appender';
import { ConsoleAppender } from 'aurelia-logging-console';
import { initialState } from './lib/state';
import { ApplicationStateManager } from 'lib/state';
import { Dbg } from 'dbg';

declare const IS_DEV_BUILD: boolean; // The value is supplied by Webpack during the build
declare const IS_LOCAL: boolean; // The value is supplied by Webpack during the build
declare const VERSION: string; // The value is supplied by Webpack during the build

window.dbg = new Dbg();

export function configure(aurelia: Aurelia) {
  aurelia.use
    .standardConfiguration()
    .feature(PLATFORM.moduleName('elements/index'))
    .feature(PLATFORM.moduleName('components/dynamic-table/index'))
    .feature(PLATFORM.moduleName('value-converters/index'))
    .feature(PLATFORM.moduleName('components/_developer/index'))
    .feature(PLATFORM.moduleName('components/validation/index'))
    .plugin(PLATFORM.moduleName('@appex/aurelia-dompurify'))
    .plugin(PLATFORM.moduleName('aurelia-validation'))
    .plugin(PLATFORM.moduleName('aurelia-dialog'))
    .plugin(PLATFORM.moduleName('aurelia-store'), { initialState })
    .plugin(PLATFORM.moduleName('aurelia-i18n'), (ai18n) => {
      ai18n.i18next.use(LocizeBackend);
      return ai18n
        .setup({
          appendNamespaceToCIMode: true,

          // NOTE! NOTE! NOTE!
          // Don't set this to true
          saveMissing: false,
          // NOTE! NOTE! NOTE!

          ns: ['notlogg'],
          defaultNS: 'notlogg',
          debug: false,
          backend: {
            projectId: 'f09ae2a9-22cc-4945-8ed8-8c26ab458979',
            apiKey: '1982d949-6d1b-4e78-8fb2-a7321339d7e0',
            referenceLng: 'en',
          },
          attributes: ['i18n', 't'],
          lng: 'nb-NO',
          fallbackLng: 'nb-NO',
        })
        .then(() => {
          const router = aurelia.container.get(AppRouter);
          router.transformTitle = (title) => ai18n.tr(title);
          const eventAggregator = aurelia.container.get(EventAggregator);
          eventAggregator.subscribe('i18n:locale:changed', () => {
            router.updateTitle();
          });
        });
    })
    .container.registerTransient(BaseApiService);

  aurelia.container.registerSingleton(ApplicationStateManager);

  configureLogging();

  void aurelia.start().then(() => {
    void aurelia.setRoot(PLATFORM.moduleName('app'));
  });
}

function configureLogging() {
  const consoleLogLevel = localStorage.getItem('aurelia:logLevel') || (IS_DEV_BUILD ? 'debug' : 'error');
  window.__netlog = {
    ...environment,
    release: VERSION,
    IS_DEV_BUILD,
    IS_LOCAL,
  };

  if (IS_DEV_BUILD || IS_LOCAL) {
    // eslint-disable-next-line no-console
    LogManager.addAppender(new ConsoleAppender());
    LogManager.setLevel(LogManager.logLevel[consoleLogLevel]);
  }

  if (environment.sentry.dsn) {
    // eslint-disable-next-line no-console
    Sentry.init({
      dsn: environment.sentry.dsn,
      release: VERSION,
      environment: environment.environment,
      integrations: [Sentry.browserTracingIntegration()],
      tracesSampleRate: environment.sentry.tracesSampleRate,
    });

    LogManager.addAppender(new SentryAppender(consoleLogLevel));
    // The log level for Sentry breadcrumbs is set here
    LogManager.setLevel(LogManager.logLevel.debug);
  }
}
