import { Role, UserModels } from 'models/UserModels';
import { ApplicationState } from './ApplicationState';
import { Store } from 'aurelia-store';
import { ListSetting } from 'elements/list-settings';

const serialize = <T>(o: T): T => JSON.parse(JSON.stringify(o));

const setUserAction = (state: ApplicationState, user: UserModels.User) => {
  state.user = Object.assign({}, user);
  return state;
};

const setAuthenticatedAction = (state: ApplicationState, authenticated: boolean) => {
  state.authenticated = authenticated;
  return state;
};

const setRolesAction = (state: ApplicationState, roles: Role[]) => {
  state.roles = roles;
  return state;
};

const setListSettings = (state: ApplicationState, settings: ListSetting) => {
  state.listSettings = settings;
  return state;
};

const setSidebarOpen = (state: ApplicationState, open: boolean) => {
  state.sidebarOpen = open;
  return state;
};

/**
 * The state handler method that directs action payload into their action handler methods.
 * The state returned from this is allways a new copy of the old state.
 * @param state the current state
 * @param acton the action to execute on the state
 */
const stateHandler = (state: ApplicationState, action: ApplicationStateAciton) => {
  const serializedOldState = serialize(state);
  return actions[action.action]?.(serializedOldState, action.payload) ?? serializedOldState;
};

/**
 * All actions available for mutating application state.
 */
export const actions: ActionExecutors = {
  setAuthenticated: setAuthenticatedAction,
  setUser: setUserAction,
  setRoles: setRolesAction,
  setListSettings: setListSettings,
  setSidebarOpen: setSidebarOpen,
};

export type ApplicationStateAciton =
  | { action: 'setRoles'; payload: Role[] }
  | { action: 'setUser'; payload: UserModels.User }
  | { action: 'setAuthenticated'; payload: boolean }
  | { action: 'setListSettings'; payload: ListSetting }
  | { action: 'setSidebarOpen'; payload: boolean };

type ActionKeys = ApplicationStateAciton['action'];
type ActionValues = ApplicationStateAciton['payload'];

type ActionExecutors = {
  [Key in ActionKeys]: (state: ApplicationState, value: ActionValues) => ApplicationState;
};

/**
 * Register the action handler for state mutation
 * @param store the store to register the action handler on
 */
export const registerActionHandler = (store: Store<ApplicationState>) => {
  store.registerAction('applicationState', stateHandler);
};
