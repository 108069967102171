import { computedFrom } from "aurelia-framework";

export class LoadingHandler {
  private counter = 0

  public async only<T>(job: Promise<T>) {
    try {
      this.counter++;
      return await job;
    } catch (error) {
      this.counter--;
      throw error;
    } finally {
      this.counter--;
    }
  }

  public async all<T extends unknown[]>(values: T) {
    this.counter++;
    try {
      return await Promise.all(values);
    } catch (error) {
      this.counter--;
      throw error;
    } finally {
      this.counter--;
    }
  }

  @computedFrom('counter')
  get isLoading() {
    return this.counter > 0;
  }
}

