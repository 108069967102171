import { Models } from '../../models/core'

/**
  * Returns how many months this service can be extended for based on
* the service NS standard.
  * Does not validate if it actually can be extended.
*/
export const getMonthsForServiceExtension = (service: Models.Service) => {
  let months = 0
  if (service.NetStandard) {
    months = service.NetStandard?.Version == Models.NetStandardVersion.NS9415_2021 ? 4 : 3;
  }
  return months
}
