import { Container } from 'aurelia-framework';
import { ErrorService } from 'services';

export function AsyncErrorHandler(_: unknown, __: string, descriptor: PropertyDescriptor) {
  const originalMethod = descriptor.value;
  const errorService = Container.instance.get(ErrorService);
  descriptor.value = async function (...args: unknown[]) {
    try {
      return await originalMethod.apply(this, args);
    } catch (error) {
      errorService.handleError(error);
    }
  };

  return descriptor;
}
