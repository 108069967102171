/**
 * Returns the given number as a decimal number with given n decimals that is not trailing zeros.
 * @param value value to reduce decimals on
 * @param n number of decimals
 */
export const reduceToDecimal = (value: number | string, n: number) => {
  let v = value as string;
  if (typeof value === 'number') {
    v = `${value.toFixed(n)}`;
  } else if (typeof value === 'string') {
    v = Number.parseFloat(v).toFixed(n);
  }
  return Number.parseFloat(v);
};

// How many favns in one meter.
const METERS_IN_FAVN = 1.8;

/**
 * Returns the given meter value as favns.
 * @param value value to convert to favns
 */
export const metersToFavn = (value: number | string | undefined) => {
  let convertedValue = 0;
  if (typeof value === 'number') convertedValue = value * METERS_IN_FAVN;
  if (typeof value === 'string' && value) convertedValue = Number.parseFloat(value) * METERS_IN_FAVN;
  return Number.parseFloat(convertedValue.toFixed(2));
};

/**
 * Returns the given favn value as meters.
 * @param value value to convert to meters
 */
export const favnToMeters = (value: number | string | undefined) => {
  let convertedValue = 0;
  if (typeof value === 'number') convertedValue = value / METERS_IN_FAVN;
  if (typeof value === 'string' && value) convertedValue = Number.parseFloat(value) / METERS_IN_FAVN;
  return Number.parseFloat(convertedValue.toFixed(2));
};
