/* eslint-disable no-console */
import { Store, connectTo } from 'aurelia-store';
import { ApplicationState } from './ApplicationState';
import { ApplicationStateAciton, registerActionHandler } from '.';

@connectTo()
export class ApplicationStateManager {
    // This gets auto injected by connectTo annotation
    private state: ApplicationState;

    private subscription: {
        unsubscribe: () => void;
    };

    constructor(private store: Store<ApplicationState>) {
        this.subscription = this.store.state.subscribe((state) => (this.state = state));
        registerActionHandler(store);
    }

    public async mutate(action: ApplicationStateAciton) {
        return await this.store.dispatch('applicationState', action);
    }

    public resubscribe() {
        this.subscription.unsubscribe();
        this.subscription = this.store.state.subscribe((state) => (this.state = state));
    }

    public toString() {
        console.group(Object.getPrototypeOf(this).constructor.name);
        console.log(JSON.stringify(this.state, null, 2));
        console.groupEnd();
    }
}
