import { ListSetting } from "elements/list-settings";

export class DataFilter {
  searchText?: string;

  orderBy: string;
  orderByDirection: 'ASC' | 'DESC' = 'DESC';

  top = 25;
  skip = 0;

  export = false;
  locale: 'nb' | 'en' | 'es' = 'nb';

  constructor(initData?: Partial<DataFilter>) {
    Object.assign(this, initData);
    this.getGlobalListSettings();
  }

  private getGlobalListSettings () {
    const globalListSettings = localStorage.getItem('LIST_SETTINGS_GLOBAL');

    if(globalListSettings){
      const listSettings = JSON.parse(globalListSettings) as ListSetting;
      this.top = listSettings.pageSize;
    }
  }

  setOrderByAndFlipOrderDirectionIfSameKey(orderBy: string) {
    if (this.orderBy == orderBy) {
      this.flipOrderBy();
    }
    this.orderBy = orderBy;
  }

  private flipOrderBy() {
    this.orderByDirection = this.orderByDirection === 'ASC' ? 'DESC' : 'ASC';
  }

  toQueryString() {
    return (
      '?' +
      new URLSearchParams(
        Object.entries(this).filter(([, v]) => !(v === null || v === undefined || v?.length == 0))
      ).toString()
    );
  }
}
